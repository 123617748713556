import BeoordeeldeTriageCriteria from "./BeoordeeldeTriageCriteria";

export default class BeoordeeldeIngangsklacht {
    
    public id: number;
    public name: string;
    public isLevensBedreigend: boolean;
    public trauma: boolean;
    public beoordeeldeTriageCriteria: BeoordeeldeTriageCriteria[]
}



