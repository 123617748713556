
import TriageCalculation from "@/components/triagecalculation.vue";
import Vue from "vue";

export default Vue.extend({
  name: "Home",

  components: {
    TriageCalculation,
  },
});
