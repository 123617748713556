
import { Vue, Component } from 'vue-property-decorator'
import TriageCalculationCriteria from  './Calucation/TriageCalculationCriteria.vue';
import TriageCalculationGeneralInformation from './Calucation/TriageCalculationGeneralInformation.vue';
import TriageCalculationAdditionalCriteria from './Calucation/TriageCalculationAdditionalCriteria.vue';
import TriageCalculationResult from './Calucation/TriageCalculationResult.vue';
import Login  from './cookies/login.vue';

@Component({
  components: {
    TriageCalculationGeneralInformation,
    TriageCalculationCriteria,
    TriageCalculationAdditionalCriteria,
    TriageCalculationResult,
    Login
    }
})
export default class TriageCalculation extends Vue {

 async created(): Promise<void> {
  var hascookie = this.$cookies.isKey('.identity');
  await this.$store.dispatch('isAuthenticated', hascookie);
  if(this.authenticated){
      await this.$store.dispatch('calculate');  
  }
 }

 get authenticated(): boolean {
   return this.$store.getters.authenticated as boolean;
 }

 get showSpinner(): boolean {
   return this.$store.getters.showLoadingIndicator as boolean;
 }

 get canCalculate(): boolean{
  return this.$store.getters.canCalculate as boolean;
 }

}
