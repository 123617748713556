

export default class TriageTypeModel {

    constructor(id: number) {
        this.id =  id;
        
    }
    public id: number;
}
