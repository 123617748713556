import BeoordeeldeTriageCriteriumAntwoord from "./BeoordeeldeTriageCriteriumAntwoord";

export default class BeoordeeldeTriageCriteria {

    public id: number;
    public name: string;
    public triageCriteriumAntwoord: BeoordeeldeTriageCriteriumAntwoord;
}



