
export default class AdditionalIngangsklachtmodel {
    Id: number;
    Ingangsklacht: string;

    public constructor(init?:Partial<AdditionalIngangsklachtmodel>) {
        Object.assign(this, init);
    }
}


