import CalculationResultAfgeleidAdvies from "./CalculationResultAfgeleidAdvies";
import CalculationResultAfgeleideUrgentieCategorie from "./CalculationResultAfgeleideUrgentieCategorie";
import CalculationResultAfgeleideVervolgactie from "./CalculationResultAfgeleideVervolgactie";

export default class CalculationResult {
    afgeleideUrgentieCategorie: CalculationResultAfgeleideUrgentieCategorie
    afgeleideVervolgactie : CalculationResultAfgeleideVervolgactie;
    afgeleideAdviezen : CalculationResultAfgeleidAdvies[];

    hasResult(): boolean {
        return this.afgeleideUrgentieCategorie != undefined && this.afgeleideVervolgactie != undefined;
    }
}

