
import Ingangsklacht from '@/Models/Ingangsklacht';
import { Vue, Component } from 'vue-property-decorator'
import _ from 'lodash';
import TriageCriteriumAntwoord from '@/Models/TriageCriteriumAntwoord';
import TriageCriterium from '@/Models/TriageCriterium';
import UpdateAnswerPayload from '@/store/Payload/UpdateAnswerPayload';
import { effectScope } from 'vue';

@Component({
})
export default class TriageCalculationCriteria extends Vue {

get ingangsklachtenToDisplay(): Ingangsklacht[] {
  var klachten = this.$store.getters.calculationInputLifeThreateningIngangsklachten;
  return  _.orderBy(klachten, ['positie', 'naam'], ['asc', 'asc']);
}

setAbcdToDefaultAnswers() : void {
  this.$store.dispatch('setAbcdToDefaultAnswers');
}
  onChange(event: any, ingangsklacht: Ingangsklacht, criterium: TriageCriterium) {
    this.$store.dispatch('updateAnswer', new UpdateAnswerPayload(ingangsklacht.id, criterium.id, event as number))
    this.$store.dispatch('calculate')
  }

 antwoordenInPosition(antwoorden: TriageCriteriumAntwoord[]): TriageCriteriumAntwoord[] {
  return  _.orderBy(antwoorden, ['sortering'], ['asc',]);
}

triageCriteriaSorted(antwoorden: TriageCriterium[]): TriageCriterium[] {
  return  _.orderBy(antwoorden, ['positie'], ['asc']);
}

criteriumIsReadOnly(criterium: TriageCriterium): boolean {
    return  criterium.isDisabled;
  }

  getToolTipHtml(disabledReasons: string[]): string {
    let html = "<ul>"
      disabledReasons.forEach(reason => {
        html = html + `<li>${reason}</li>`
      });

    return html;
  } 
  
}
