import LoginRequestModel from "@/Models/LoginRequestModel";
import LoginResponseModel from "@/Models/LoginResponseModel";
import axios, { AxiosInstance, AxiosResponse } from "axios";

export default class Loginservice {

    private readonly client: AxiosInstance;
    constructor() {
        this.client = axios.create({
            baseURL: `${process.env.VUE_APP_API_URI}/api/v1/`,
            headers: { 'Content-Type': 'application/json', 'X-API-Key':process.env.VUE_APP_API_KEY }
          })

    }
  async login(email: string, password: string): Promise<LoginResponseModel  | null> {
    let data: LoginResponseModel | null = null;
    await  this.client.post<LoginResponseModel>('login', new LoginRequestModel(email, password)) .then((response) => { 
      data = response.data;
        })
      .catch((error) => { 
        data = null;
      });
      return data;
  }
}
