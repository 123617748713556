
import Geslacht from '@/Models/Geslacht';
import TriageStart from '@/Models/TriageStart';
import TriageType from '@/Models/TriageType';
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class TriageCalculationGeneralInformation extends Vue {
  
  get triageStart(): TriageStart {
      return this.$store.state.calculationInput.triageStart;
  }

  change(): void {
      this.$store.dispatch('calculate');
  }

  get triageTypes(): TriageType[] {
    var triageTypes:TriageType[] = [];
    triageTypes.push( new TriageType({ id : 0, name : ""})) 
    triageTypes.push( new TriageType({ id : 1, name : "Telefonisch"})) 
    triageTypes.push( new TriageType({ id : 2, name : "Fysiek"})) 
    return triageTypes;
  }

  reset(): void {
    this.$store.dispatch('reset');
  }

  get geslachten(): Geslacht[] {
    var geslachten:Geslacht[] = [];
    geslachten.push( new Geslacht({ aanduiding : ""})) 
    geslachten.push( new Geslacht({ aanduiding : "man"})) 
    geslachten.push( new Geslacht({ aanduiding : "vrouw"})) 
    geslachten.push( new Geslacht({ aanduiding : "onbekend"})) 
    return geslachten;
  }

  get leeftijdUnits(): any[] {
      return [{value:'',text:''},{value:'dag',text:'dag'},{value:'week',text:'week'},{value:'maanden',text:'maanden'},{value:'jaren',text:'jaar'}];
  }
}
