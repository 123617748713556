import TriageStart from "@/Models/TriageStart";
import PersoonModel from "./PersoonModel";
import TriageTypeModel from "./TriageTypeModel";

export default class TriageStartModel {

    constructor(triageStart: TriageStart) {
        this.triageType = new TriageTypeModel(triageStart.triageType);
        this.persoon = new PersoonModel(triageStart.geslacht, triageStart.leeftijd, triageStart.leeftijdEenheid);
    }
    public triageType: TriageTypeModel;
    public persoon: PersoonModel;
}