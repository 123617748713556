
import LoginPayload from '@/store/Payload/LoginPayload';
import { Vue, Component } from 'vue-property-decorator'


@Component({})
export default class Login extends Vue {
private email = '';
private password ='';

mounted(): void {
  if(!this.authenticated){
      this.$bvModal.show('my-modal') 
    }
  }


async login(): Promise<void> {
  await this.$store.dispatch('login', new LoginPayload(this.email, this.password));
  if(this.authenticated){
   this.$cookies.set('.identity',this.userName, 0);
   await this.$store.dispatch('calculate');
  }
 }

get authenticated(): boolean {
   return this.$store.getters.authenticated as boolean;
 }

 get loggingIn(): boolean {
   return this.$store.getters.loggingIn as boolean;
 }

get userName(): boolean {
   return this.$store.getters.userName as boolean;
 }
}
