import CalculationInput from "@/Models/CalculationInput";
import CalculationResult from "@/Models/CalculationResult";
import Ingangsklacht from "@/Models/Ingangsklacht";
import axios, { AxiosInstance } from "axios";
import BeoordeeldeIngangsklacht from "./Models/BeoordeeldeIngangsklacht";
import BeoordeeldeTriageCriteria from "./Models/BeoordeeldeTriageCriteria";
import BeoordeeldeTriageCriteriumAntwoord from "./Models/BeoordeeldeTriageCriteriumAntwoord";
import CalculatieInputModel from "./Models/CalculatieInputModel";
import CalculatieInputTriageStartModel from "./Models/TriageStartModel";
import CombinedResult from "./Models/CombinedResult";

export default class CombinedCalculationService {
  
  private readonly client: AxiosInstance;
  constructor() {
      this.client = axios.create({
          baseURL: `${process.env.VUE_APP_API_URI}/api/v1/`,
          headers: { 'Content-Type': 'application/json', 'X-API-Key':process.env.VUE_APP_API_KEY }
        })

  }
   async calculate(input: CalculationInput): Promise<CombinedResult | null> {
      let data: CombinedResult | null = null;
      const apiModel = new CalculatieInputModel();
      apiModel.triageStart = new CalculatieInputTriageStartModel(input.triageStart);

      const klachtenForCalculation : BeoordeeldeIngangsklacht[] = [];
      if(input.ingangsklachten !== undefined){
        input.ingangsklachten.forEach(ingangsklacht => {
        
          const ingangsKlachtForCalculation = this.getIngangsklachtForCalculation(ingangsklacht);
          
          if(ingangsKlachtForCalculation != null) klachtenForCalculation.push(ingangsKlachtForCalculation);
        });
      }

      apiModel.beoordeeldeIngangsklachten = klachtenForCalculation;

      await  this.client.post<CombinedResult>('combinedcalculaties', apiModel) .then((response) => { 
            data = response.data;
        })
      .catch(() => { 
        data = null;
      });
      return data;
    }

    private getIngangsklachtForCalculation(ingangsklacht: Ingangsklacht) : BeoordeeldeIngangsklacht | null
    {
        //Nothing to return
        if(!ingangsklacht.isSelectedAsAditional && !ingangsklacht.isLevensBedreigend) return null;
        
        const returnResult: BeoordeeldeIngangsklacht = new BeoordeeldeIngangsklacht();
        returnResult.id = ingangsklacht.id;
        returnResult.isLevensBedreigend = ingangsklacht.isLevensBedreigend;
        returnResult.name = ingangsklacht.naam;
        returnResult.trauma = false;

        const beoordeldeCriteria:BeoordeeldeTriageCriteria[] = [];
        ingangsklacht.mogelijkeTriageCriteria.forEach(element => {
          if(element.selectedAntwoordId){
            const beoordeeldeTriageCriteria = new BeoordeeldeTriageCriteria();
            beoordeeldeTriageCriteria.id = element.id;
            beoordeeldeTriageCriteria.name = element.naam;

            const answer = new BeoordeeldeTriageCriteriumAntwoord();
            answer.id = element.selectedAntwoordId;
            beoordeeldeTriageCriteria.triageCriteriumAntwoord = answer;

            beoordeldeCriteria.push(beoordeeldeTriageCriteria);
          }
        });
        returnResult.beoordeeldeTriageCriteria = beoordeldeCriteria;
        return returnResult;
    }
  }

  

