
export default class UpdateAnswerPayload {
    constructor(klachtId: number, criteriumId: number, answerId: number | null) {
        this.klachtId = klachtId;
        this.criteriumId = criteriumId;
        this.answerId = answerId;
    }
    klachtId: number;
    criteriumId: number;
    answerId: number | null;
}
