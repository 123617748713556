

export default class TriageType {
    id: number;
    name: string;

    constructor(init?:Partial<TriageType>) {
        Object.assign(this, init);
    }
}
