import CalculationInput from '@/Models/CalculationInput'
import CalculationResult from '@/Models/CalculationResult'
import Loginservice from '@/services/LoginService'
import { BToast } from 'bootstrap-vue'
import _ from 'lodash'
import Vue from 'vue'
import Vuex from 'vuex'
import LoginPayload from './Payload/LoginPayload'
import SetAdditionalKlachtSelectedPayload from './Payload/SetAdditionalKlachtSelectedPayload'
import UpdateAnswerPayload from './Payload/UpdateAnswerPayload'
import CombinedCalculationService from '@/services/CombinedCalculationService'
import TriageCriteriumAntwoord from '@/Models/TriageCriteriumAntwoord'

Vue.use(Vuex)

export default new Vuex.Store ({
  state: {
    calculationInput : new CalculationInput(),
    calculationResult : new CalculationResult(),
    calculating : false,
    authenticated : false,
    loggingIn : false,
    userName: ''
  },
  mutations: {
    async calculate (state) {
        state.calculating = true;
        const result = await new CombinedCalculationService().calculate(state.calculationInput);
      
        if(result != null){
          const emptyAnswer = new TriageCriteriumAntwoord();
          emptyAnswer.omschrijving = "";
          emptyAnswer.sortering = -1;
          
          result.ingangsklachten.forEach(ingangsklacht => {
            ingangsklacht.mogelijkeTriageCriteria.forEach( mogelijkeTriageCriteria => {
              mogelijkeTriageCriteria.mogelijkeTriageCriteriumAntwoorden.push(emptyAnswer)
            })
          });
        }
        let input = state.calculationInput;

        if(input.ingangsklachten === undefined || input.ingangsklachten.length == 0){
          input = new CalculationInput();
        }   

        if(result != null) {
          state.calculationResult = result.calculationResult;

          if(input.ingangsklachten !== undefined)
          {
            input.ingangsklachten.forEach(ingangsklacht => {
              const ingangsKlachtNew = result.ingangsklachten.find(el => el.id == ingangsklacht.id);
    
              ingangsklacht.mogelijkeTriageCriteria.forEach(mogelijkeTriageCriteria => {
                
                const criteriaNew = ingangsKlachtNew?.mogelijkeTriageCriteria.find(el => el.id == mogelijkeTriageCriteria.id);
    
                if(criteriaNew !== undefined){
                  mogelijkeTriageCriteria.isDisabled = criteriaNew.isDisabled;
                  mogelijkeTriageCriteria.disabledReasons = criteriaNew.disabledReasons;
                }
                
              });
            });
          }
          else {
            input.ingangsklachten = result.ingangsklachten;
          }
        }
        else{
          const bootStrapToaster = new BToast();

          bootStrapToaster.$bvToast.toast("Er is een fout opgetreden tijdens het berekenen.", {
              title: "Fout!",
              toaster: "b-toaster-top-center",
              variant : "danger",
              solid: true,
              appendToast: false
            })
        }

      state.calculationInput = input;
      state.calculating = false;
    },
    setCalculating (state, calculating: boolean) {
        state.calculating = calculating;
    },
    setCalculationResult (state, calculationResult: CalculationResult) {
      state.calculationResult = calculationResult;
    },
    isAuthenticated (state, authenticated: boolean) {
      state.authenticated = authenticated;
    },
    loggingIn(state, loggingIn: boolean){
      state.loggingIn = loggingIn;
    },
    authenticatedUser(state, userName: string){
      state.userName = userName;
    },
    changeAdditionalKlachtAsSelected (state, setAdditionalKlachtSelectedPayload: SetAdditionalKlachtSelectedPayload ) {
      const index = state.calculationInput.ingangsklachten.findIndex( el => el.id == setAdditionalKlachtSelectedPayload.klachtId);
      const ingangsklacht = state.calculationInput.ingangsklachten[index];
      ingangsklacht.isSelectedAsAditional = setAdditionalKlachtSelectedPayload.selected;

      state.calculationInput.ingangsklachten[index] = ingangsklacht;
      state.calculationInput.ingangsklachten = [...state.calculationInput.ingangsklachten]
    },

    updateAnswer(state, updateAnswerPayload: UpdateAnswerPayload ) {
      const index = state.calculationInput.ingangsklachten.findIndex( el => el.id == updateAnswerPayload.klachtId);
      const ingangsklacht = state.calculationInput.ingangsklachten[index];
      
      ingangsklacht.mogelijkeTriageCriteria.forEach(criterium => {
        if(criterium.id == updateAnswerPayload.criteriumId){
          criterium.selectedAntwoordId = updateAnswerPayload.answerId;
        }
      });
      
        state.calculationInput.ingangsklachten.forEach(ingangsKlacht => {
          ingangsKlacht.mogelijkeTriageCriteria.forEach(criterium => {
            if(criterium.id == updateAnswerPayload.criteriumId){
              criterium.selectedAntwoordId = updateAnswerPayload.answerId;
            }
          })
      })

      state.calculationInput.ingangsklachten[index] = ingangsklacht;
      state.calculationInput.ingangsklachten = [...state.calculationInput.ingangsklachten]
    }
  },
  actions: {
    setAdditionalKlachtAsSelected (context, setAdditionalKlachtSelectedPayload: SetAdditionalKlachtSelectedPayload ) {
      context.commit('changeAdditionalKlachtAsSelected', setAdditionalKlachtSelectedPayload);
    },
     updateAnswer(context, updateAnswerPayload: UpdateAnswerPayload ) {
      context.commit('updateAnswer', updateAnswerPayload);
    },
    calculate(context) {
      context.commit('calculate');

    },
    async login (context, loginpayload: LoginPayload) {
      context.commit('loggingIn', true);
      const loginResult = await new Loginservice().login(loginpayload.userName, loginpayload.password);
      const bootStrapToaster = new BToast();

      if(loginResult === null){
        bootStrapToaster.$bvToast.toast("Er is iets fout gegaan tijdens het inloggen, probeer het opnieuw.", {
            title: "Fout!",
            toaster: "b-toaster-top-center",
            variant : "danger",
            solid: true,
            appendToast: false
          })

          context.commit('loggingIn', false);
          context.commit('isAuthenticated', false);
          return;
      }

      if(loginResult.success === false){
          bootStrapToaster.$bvToast.toast("Invalide gebruikersnaam of wachtwoord", {
              title: "Fout!",
              toaster: "b-toaster-top-center",
              variant : "danger",
              solid: true,
              appendToast: false
            })
      }

      if(loginResult.success){
        context.commit('authenticatedUser', loginResult.user);
      }

      context.commit('isAuthenticated', loginResult.success);
      context.commit('loggingIn', false);
    },
    isAuthenticated (context, isAuthenticated: boolean) {
      context.commit('isAuthenticated', isAuthenticated);
    },
    reset(context){
      this.state.calculationInput.ingangsklachten.forEach(klacht => {
        context.commit('changeAdditionalKlachtAsSelected', new SetAdditionalKlachtSelectedPayload(klacht.id, false));
        klacht.mogelijkeTriageCriteria.forEach(criteria => {
          context.commit('updateAnswer', new UpdateAnswerPayload(klacht.id, criteria.id, null));
        });

      })
      context.commit('calculate');
    },

    setAbcdToDefaultAnswers(context){
      this.getters.calculationInputLifeThreateningIngangsklachten.filter(el => el.id !== 56).forEach(klacht => {
        klacht.mogelijkeTriageCriteria.forEach(criteria => {
          const answer = _.orderBy(criteria.mogelijkeTriageCriteriumAntwoorden.filter(antwoord => antwoord.sortering !== 999), ['sortering'], ['desc',])[0]
          context.commit('updateAnswer', new UpdateAnswerPayload(klacht.id, criteria.id, answer.id));
        });

      });
      context.commit('calculate');
    }
  },
  getters: {
    showLoadingIndicator: state => {
      return state.calculating;
    },
    canCalculate: state => {
      return state.calculationInput.canCalculate();
    },
    calculationInputTriageStart: state => {
      return state.calculationInput.triageStart;
    },
    calculationInputLifeThreateningIngangsklachten: state => {
      if(!state.calculationInput.ingangsklachten) return [];
      return state.calculationInput.ingangsklachten.filter(el => el.isLevensBedreigend);
    },
    calculationInputAdditionalIngangsklachtenSelected: state => {
      if(!state.calculationInput.ingangsklachten) return [];
      return state.calculationInput.ingangsklachten.filter(el => !el.isLevensBedreigend && el.isSelectedAsAditional);
    },
    calculationInputAdditionalIngangsklachtenSelectable: state => {
      if(!state.calculationInput.ingangsklachten) return [];
      return state.calculationInput.ingangsklachten.filter(el => !el.isLevensBedreigend && !el.isSelectedAsAditional);
    },
    calculationResult: state => {
      return state.calculationResult;
    },
    authenticated: state => {
      return state.authenticated;
    },
    loggingIn: state => {
      return state.loggingIn;
    },
    userName: state => {
      return state.userName;
    }
  }
})
