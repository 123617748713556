
import CalculationInput from '@/Models/CalculationInput';
import CalculationResult from '@/Models/CalculationResult';
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class TriageCalculationResult extends Vue {

get isInputValid() : boolean {
  return this.$store.getters.canCalculate as boolean;
}

  get calulationOutput(): CalculationResult{
    return this.$store.state.calculationResult;
  }

  get calculationInput(): CalculationInput{
    return this.$store.state.calculationInput;
  }

  // constructHtml(reasons: string[]): string {
  //   var html = "<ul>";
  //   reasons.forEach(reason => {
  //     html = html + `<li>${reason}</li>`
  //   });

  //   html = html + "</ul>";
  //   return html;
  // }

  constructCssClass(isDisabled: boolean): string {
    var classes = "d-flex justify-content-between align-items-center no-borders font-weight-normal";
    if(isDisabled) classes = classes + " disabled-advies";
    return classes;
  }
}
