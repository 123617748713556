import PersoonGeslachtModel from "./PersoonGeslachtModel";
import PersoonLeeftijdModel from "./PersoonLeeftijdModel";


export default class PersoonModel {
    public geslacht: PersoonGeslachtModel;
    public leeftijd: PersoonLeeftijdModel;

    constructor(geslacht: string, leeftijd:number, leeftijdEenheid: string){
        this.geslacht = new PersoonGeslachtModel(geslacht);
        this.leeftijd = new PersoonLeeftijdModel(leeftijd, leeftijdEenheid);
    }
}
