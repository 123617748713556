export default class SetAdditionalKlachtSelectedPayload
{
    constructor(klachtId: number, selected: boolean) {
        this.klachtId = klachtId;
        this.selected = selected;

    }
        klachtId: number;
        selected: boolean;
}

