import TriageStart from "./TriageStart";
import Ingangsklacht from "./Ingangsklacht";

export default class CalculationInput {
    triageStart: TriageStart = new TriageStart();
    ingangsklachten: Ingangsklacht[];

    canCalculate(): boolean {
        return this.triageStart.geslacht != ''  
        && this.triageStart.leeftijd >= 0
        && this.triageStart.leeftijdEenheid != undefined
        && this.triageStart.leeftijdEenheid != ''
        && this.triageStart.triageType != undefined && this.triageStart.triageType != 0;
    }
}


