
import Ingangsklacht from "@/Models/Ingangsklacht";
import AdditionalIngangsklachtmodel from "@/Models/tables/AdditionalIngangsklachtmodel";
import TriageCriteriumAntwoord from "@/Models/TriageCriteriumAntwoord";
import { Vue, Component } from "vue-property-decorator";
import _ from "lodash";
import TriageCriterium from "@/Models/TriageCriterium";
import SetAdditionalKlachtSelectedPayload from "@/store/Payload/SetAdditionalKlachtSelectedPayload";
import UpdateAnswerPayload from "@/store/Payload/UpdateAnswerPayload";

@Component
export default class TriageCalculationAdditionalCriteria extends Vue {
  private filter: string | null = "";
  private totalRows: number = 0;
  private filterOn = ["Ingangsklacht"];
  private selectedInTable: AdditionalIngangsklachtmodel[] = [];

  get fields(): string[] {
    return ["Ingangsklacht"];
  }

  handleOk(bvModalEvt: any): void {
    this.selectedInTable.forEach((element) => {
      this.$store.dispatch(
        "setAdditionalKlachtAsSelected",
        new SetAdditionalKlachtSelectedPayload(element.Id, true)
      );
    });
    this.$store.dispatch('calculate');
  }

  onChange(event: any, ingangsklacht: Ingangsklacht, criterium: TriageCriterium) {
    this.$store.dispatch('updateAnswer', new UpdateAnswerPayload(ingangsklacht.id, criterium.id, event as number))
    this.$store.dispatch('calculate')
  }

  removeAdditionalIngangsklachtFormSelection(id: number): void {
    this.$store.dispatch(
      "setAdditionalKlachtAsSelected",
      new SetAdditionalKlachtSelectedPayload(id, false)
    );
    this.$store.dispatch('calculate');
  }
  resetModal(): void {
    this.selectedInTable = [];
  }
  onFiltered(filteredItems: any): void {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRows = filteredItems.length;
  }

  onRowSelected(items: AdditionalIngangsklachtmodel[]): void {
    this.selectedInTable = items;
  }

  antwoordenInPosition(
    antwoorden: TriageCriteriumAntwoord[]
  ): TriageCriteriumAntwoord[] {
    return _.orderBy(antwoorden, ["sortering"], ["asc"]);
  }

  triageCriteriaSorted(criteria: TriageCriterium[]): TriageCriterium[] {
    return _.orderBy(criteria, ["positie"], ["asc"]);
  }

  get ingangsklachtenToDisplay(): Ingangsklacht[] {
    return this.$store.getters
      .calculationInputAdditionalIngangsklachtenSelected;
  }
  
  getToolTipHtml(disabledReasons: string[]): string {
    let html = "<ul>"
      disabledReasons.forEach(reason => {
        html = html + `<li>${reason}</li>`
      });

    return html;
  } 

  get ingangsklachtenSelectable(): AdditionalIngangsklachtmodel[] {
    var storeData = this.$store.getters
      .calculationInputAdditionalIngangsklachtenSelectable as Ingangsklacht[];

    this.totalRows = storeData.length;
    return storeData.map(
      (el) =>
        new AdditionalIngangsklachtmodel({
          Ingangsklacht: el.naam,
          Id: el.id
        })
    );
  }
}
