
export default class PersoonLeeftijdModel {

    constructor(aantal:number, leeftijdEenheid: string) {
       this.aantal = aantal;
       this.eenheid = leeftijdEenheid;     

    }
    public aantal: number;
    public eenheid: string;
}
